
import { Vue, Component, Prop } from "vue-property-decorator";
import { mdiAccount, mdiPen, mdiTrashCan } from "@mdi/js";
import { UserObjectDataSource } from "@/data/Object/UserObjectDataSource";
import { ListDataSource } from "@/data/List/ListDataSource";
@Component({
  components: {},
})
export default class AccountProfile extends Vue {
  @Prop() dataSource!: UserObjectDataSource;
  loaded: boolean = false;
  $confirm: any;
  $message: any;
  icons: any = {
    profile: mdiAccount,
    pen: mdiPen,
    trash: mdiTrashCan,
  };
  consentDataSource: ListDataSource = new ListDataSource({
    className: "consent",
    config: {
      pageSize: 100,
    },
  });

  userConsentDataSource: ListDataSource = new ListDataSource({
    className: "UserConsent",
    config: {
      pageSize: 100,
      filter: JSON.stringify([
        {
          fieldName: "userProfileId",
          fieldValue: +this.$route.params.id,
        },
      ]),
    },
  });

  getValue(consent) {
    const { items } = this.userConsentDataSource;
    if (!Array.isArray(items)) return false;
    const userConsent = items.find((c: any) => +c.consentId === +consent.id);
    return userConsent?.value ?? false;
  }

  async addOrChangeUserConsent(event, consent) {
    const items = this.userConsentDataSource.items ?? [];
    const cb = (c: any) => +c.consentId === +consent.id;
    const userConsent = items.find(cb) ?? {
      userProfileId: +this.$route.params.id,
      consentId: consent.id,
      value: true,
    };

    userConsent.value = event;

    try {
      await this.userConsentDataSource.add(userConsent);
      this.$message("Успешно сохранено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async created() {
    await Promise.all([
      this.consentDataSource.get(),
      this.userConsentDataSource.get(),
    ]);
  }
}
