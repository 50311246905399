
import { Vue, Component, Ref, Prop } from "vue-property-decorator";
import AppForm from "@/components/app-form/app-form.vue";
import { mdiAccount, mdiPen, mdiTrashCan } from "@mdi/js";
import { UserObjectDataSource } from "@/data/Object/UserObjectDataSource";
import FileUploader from "@/components/helpers/file-uploader-dialog.vue";
import ImageEditor from "@/components/image-editor.vue";
import { ListDataSource } from "@/data/List/ListDataSource";
@Component({
  components: { AppForm, FileUploader, ImageEditor },
})
export default class AccountProfile extends Vue {
  @Prop() dataSource!: UserObjectDataSource;
  @Prop() userConsentDataSource!: ListDataSource;
  @Ref("form") from!: any;
  @Ref("uploader") uploader!: any;
  @Ref("editor") editor!: any;

  loaded: boolean = false;
  $confirm: any;
  $message: any;
  fileUploaderVisible = false;

  icons: any = {
    profile: mdiAccount,
    pen: mdiPen,
    trash: mdiTrashCan,
  };

  container: any = {
    name: "grid",
  };

  actions: any = {
    getGenders: () => [
      {
        id: 1,
        caption: "Мужской",
      },
      {
        id: 2,
        caption: "Женский",
      },
      {
        id: 3,
        caption: "Не определён",
      },
    ],
  };

  fields = [
    {
      attrs: {},
      cols: [
        {
          attrs: {
            cols: 12,
          },
          fields: [
            {
              editor: "title",
              attrs: {
                class: "mb-3",
                title: "Персональные данные",
              },
            },
          ],
        },
      ],
    },
    {
      attrs: {},
      cols: [
        {
          attrs: {
            lg: 8,
            cols: 12,
            order: 2,
            orderLg: 1,
          },
          fields: [
            {
              editor: "string",
              attrs: {
                type: "text",
                outlined: true,
                label: "Фамилия",
              },
              name: "lastName",
              validations: [
                {
                  validator: "required",
                  errorMessage: "Поле не может быть пустым",
                },
              ],
            },
            {
              editor: "string",
              attrs: {
                type: "text",
                outlined: true,
                label: "Имя",
              },
              name: "firstName",
              validations: [
                {
                  validator: "required",
                  errorMessage: "Поле не может быть пустым",
                },
              ],
            },
            {
              editor: "string",
              attrs: {
                type: "text",
                outlined: true,
                label: "Отчество",
                hint: "Не обязательно",
              },
              name: "patronymic",
            },
            {
              editor: "string",
              attrs: {
                type: "text",
                outlined: true,
                label:
                  "Фамилия Имя на английском языке для отображения в сертификате",
              },
              name: "fullNameInEnglish",
            },
            {
              editor: "enum",
              attrs: {
                dense: true,
                label: "Пол",
              },
              name: "genderId",
              validation: [
                {
                  validator: "required",
                  errorMessage: "Поле не может быть пустым",
                },
              ],
              config: {
                attrs: {
                  xSmall: true,
                },
                getItems: "getGenders",
                labelKeyName: "caption",
                valueKeyName: "id",
              },
            },
          ],
        },
        {
          attrs: {
            lg: 4,
            cols: 12,
            order: 1,
            orderLg: 2,
          },
          fields: [
            {
              editor: "slot",
              slotName: "avatar",
            },
          ],
        },
      ],
    },
    {
      attrs: {},
      cols: [
        {
          attrs: {
            cols: 12,
          },
          fields: [
            {
              editor: "date",
              attrs: {},
              name: "birthDate",
              validations: [
                {
                  validator: "required",
                  errorMessage: "поле не может быть пустым",
                },
              ],
              config: {
                attrs: {
                  outlined: true,
                  label: "Дата рождения",
                  appendIcon: "mdi-calendar",
                },
              },
            },
          ],
        },
      ],
    },
    {
      attrs: {},
      cols: [
        {
          attrs: {
            cols: 12,
          },
          fields: [
            {
              editor: "title",
              attrs: {
                class: "mb-3",
                title: "Контактные данные",
              },
            },
          ],
        },
      ],
    },
    {
      attrs: {},
      cols: [
        {
          attrs: {
            cols: 12,
          },
          fields: [
            {
              editor: "string",
              attrs: {
                type: "text",
                outlined: true,
                label: "Рабочий телефон",
                mask: "+7(###) ###-##-##",
              },
              name: "phone",
              validations: [
                {
                  validator: "required",
                  errorMessage: "Поле не может быть пустым",
                  value: true,
                },
                {
                  validator: "min",
                  errorMessage: "Поле не может содержать меньше 15 символов",
                  value: 15,
                },
              ],
            },
            {
              editor: "string",
              attrs: {
                type: "text",
                outlined: true,
                label: "Мобильный телефон",
                mask: "+7(###) ###-##-##",
              },
              name: "mobilePhone",
            },
            {
              editor: "string",
              attrs: {
                type: "email",
                outlined: true,
                label: "Почта",
              },
              name: "email",
              validations: [
                {
                  validator: "required",
                  value: true,
                  errorMessage: "Поле не может быть пустым",
                },
                {
                  validator: "regular",
                  value: "email",
                  errorMessage: "Введите корректный адрес",
                },
              ],
            },
            {
              editor: "string",
              attrs: {
                type: "text",
                outlined: true,
                label: "Домашний адрес",
              },
              name: "address",
            },
            {
              editor: "string",
              attrs: {
                type: "text",
                outlined: true,
                label: "Рабочий адрес",
              },
              name: "companyAddress",
            },
          ],
        },
      ],
    },
    {
      attrs: {},
      cols: [
        {
          attrs: {
            cols: 12,
          },
          fields: [
            {
              editor: "title",
              attrs: {
                class: "mb-3",
                title: "Место работы",
              },
            },
          ],
        },
      ],
    },
    {
      attrs: {},
      cols: [
        {
          attrs: {
            cols: 12,
          },
          fields: [
            {
              editor: "string",
              attrs: {
                type: "text",
                outlined: true,
                label: "Название организации",
              },
              name: "company",
              validations: [
                {
                  validator: "required",
                  errorMessage: "Поле не может быть пустым",
                },
              ],
            },
            {
              editor: "string",
              attrs: {
                type: "text",
                outlined: true,
                label: "Должность",
              },
              name: "jobPosition",
              validations: [
                {
                  validator: "required",
                  errorMessage: "Поле не может быть пустым",
                },
              ],
            },
            {
              editor: "string",
              attrs: {
                type: "text",
                outlined: true,
                label: "Направление деятельности",
              },
              name: "professionalArea",
              validations: [
                {
                  validator: "required",
                  errorMessage: "Поле не может быть пустым",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  async deleteProfileImage() {
    const _confirm = await this.$confirm(
      "Вы действительно хотите удалить фото профиля?",
    );
    if (!_confirm) return;
    try {
      await this.dataSource.deleteProfileImage();
      this.$message("Успешно удалён");
    } catch (error) {
      this.$message(error);
    }
  }

  async uploadProfileImage(image: any): Promise<any> {
    try {
      await this.dataSource.uploadProfileImage(image);
      this.fileUploaderVisible = false;
    } catch (error) {
      console.error(error);
    }
  }

  async updateProfileImage(): Promise<any> {
    const image: any = await this.editor.edit(this.dataSource.image);
    if (!image) return;
    try {
      await this.dataSource.updateProfileImage(image);
      this.$message("Фото профиля успешно обновлено");
    } catch (error) {
      console.error(error);
    }
  }

  async updateProfileField(filed: any) {
    try {
      await this.dataSource.updateProfileField(filed);
      this.$message("Успешно обновлён");
    } catch (error) {
      this.$message(error);
    }
  }
}
